// put site-wide dependencies here.
// HTMX setup: https://htmx.org/docs/#installing
import htmx from 'htmx.org';

import './htmx';
import 'htmx.org/dist/ext/ws';
import './alpine';

// Import Highcharts and make it globally available
import Highcharts from 'highcharts';
import HighchartsStock from 'highcharts/modules/stock';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsAnnotations from 'highcharts/modules/annotations';
import HighchartsAnnotationsAdvanced from 'highcharts/modules/annotations-advanced';
import HighchartsSeriesLabel from 'highcharts/modules/series-label';

// Initialize Highcharts modules
HighchartsStock(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
HighchartsAccessibility(Highcharts);
HighchartsMore(Highcharts);
HighchartsAnnotations(Highcharts);
HighchartsAnnotationsAdvanced(Highcharts);
HighchartsSeriesLabel(Highcharts);

window.Highcharts = Highcharts;

// document.addEventListener('DOMContentLoaded', () => {
//     console.log('HTMX loaded:', htmx);
//     console.log('Alpine loaded:', Alpine);
//     console.log('Highcharts loaded:', Highcharts);
// });

// Import jQuery and make it globally available
import $ from 'jquery';
window.$ = $;
window.jQuery = $;

import jQuery from "jquery";
import jszip from 'jszip';
import pdfmake from 'pdfmake';
import DataTable from 'datatables.net-dt';
import 'datatables.net-buttons-dt';
import DataTableEditor from '@datatables.net/editor';
import 'datatables.net-buttons/js/buttons.colVis.mjs';
import 'datatables.net-buttons/js/buttons.html5.mjs';
import 'datatables.net-buttons/js/buttons.print.mjs';
import 'datatables.net-colreorder-dt';
import DateTime from 'datatables.net-datetime';
import 'datatables.net-fixedcolumns-dt';
import 'datatables.net-fixedheader-dt';
import 'datatables.net-responsive-dt';
import 'datatables.net-rowgroup-dt';
import 'datatables.net-rowreorder-dt';
import 'datatables.net-select-dt';
import 'datatables.net-staterestore-dt';

window.DataTable = DataTable;
window.DataTableEditor = DataTableEditor;


